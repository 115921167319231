<template>
  <div class="root for-sp">
    <transition name="overlay">
      <div v-if="isShown" class="modal-content-overlay">
      </div>
    </transition>
    <transition name="modal">
      <div v-if="isShown" class="modal-content" id="gdpr-modal-bottom">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="text fs-s">
              <p>
                We use cookies as described in our <a href="/about/cookie_policy" target="_blank">Cookie Policy</a>. By continuing to use our site or clicking "OK", you consent to our use of cookies.
              </p>
            </div>
            <div class="button-wrapper">
              <button @click="close">OK</button>
              <div class="text fs-s">
                <a @click="showUpdates" class="click-text">Terms and privacy policy updates<i class="fas fa-caret-down modal-fa-caret-down ml-b va-m fs-l"></i></a>
              </div>
            </div>
            <div class="policy-updates">
              <div class="text fs-s">
                <p>
                  <a href="/about/terms_of_use" target="_blank">Terms of Use Agreement:</a> updated effective March 27, 2025
                </p>
                <p>
                  <a href="/about/privacy_policy" target="_blank">Privacy Policy:</a> updated effective March 27, 2025
                </p>
                <p>
                  <a href="/about/cookie_policy" target="_blank">Cookie Policy:</a> updated effective October 22, 2021
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped lang="scss">
  .modal-content-overlay {
    position: fixed;
    top: 0px;
    right: 0px;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    z-index: 10000;
  }
  .modal-content {
    position: fixed;
    z-index: 100001; // anchor広告より上に
    bottom: 0;
    left: 0;
    width: 100%;
    display: table;
    background: #F5F6F9;
  }
  .modal-wrapper {
    position: relative;
    margin: 0;
  }
  .modal-container {
    height: 100%;
    font-family: Verdana,Arial;
    padding: 10px 16px;
    box-sizing: border-box;
    background-color: #fff;
    color: #787878;
    border: 1px solid #1D439B;
    box-sizing: border-box;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
  }
  .text {
    font-size: 12px;
    line-height: 1.5em;
    text-align: left;
    p {
      padding-bottom: 2px;
    }
    a {
      text-decoration: none;
      font-weight: bold;
    }
  }
  .button-wrapper {
    height: 38px;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    button {
      width: 87px;
      height: 38px;
      background-color: #4165BA;
      border: #fff 0 solid;
      border-radius: 2px;
      color: #fff;
      cursor: pointer;
      display: block;
      font-family: Avenir,lucida grande,tahoma,verdana,arial,sans-serif;
      font-size: 16px;
      line-height: 1.0em;
      text-align: center;
      text-decoration: none;
      font-weight: bold;
      float: left;
      &:active {
        background-color: rgba(#4165BA, 0.7);
      }
    }
    a {
      line-height: 38px;
      text-decoration: none;
      font-weight: bold;
      margin-left: 16px;
    }
  }
  .policy-updates {
    display: none;
  }
  .click-text {
    cursor: pointer;
  }

  /* Transition Effect */
  .overlay-enter-active, .overlay-leave-active {
    transition: opacity 0.5s;
  }
  .overlay-enter, .overlay-leave-to {
    opacity: 0;
  }
  .modal-enter-active, .modal-leave-active {
    transition: transform 0.5s;
  }
  .modal-enter, .modal-leave-to {
    -webkit-transform: translateY(300px);
    transform: translateY(300px);
  }
</style>

<script type="text/babel">
  import Cookies from "js-cookie"
  const axios = window.axios
  export default {
    mounted() {
      this.isShown = false
      $(document).ready(() => {
        let height = $("#gdpr-modal-bottom").height()
        $("body").css({paddingBottom: height})
        window.setTimeout(this.show,3000)
      })
    },
    data() {
      return {
        isShown : false
      }
    },
    methods: {
      show() {
        // 優先順位の高いポップアップが出ていたら表示しない
        if (($('.app_gdpr--2k2uB').length > 0 && $('.banner_hidden--MXmJ_').length === 0) || $('._cm-os-slider').length > 0 || $('#tbl-next-up').length > 0) {
          return
        }
        // クエリーにuser_agent=mal-(ios|android)-appがあったら表示しない
        let getParam = function(name, url) {
          if (!url) url = window.location.href;
          name = name.replace(/[\[\]]/g, "\\$&");
          var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
              results = regex.exec(url);
          if (!results) return null;
          if (!results[2]) return '';
          return decodeURIComponent(results[2].replace(/\+/g, " "));
        }

        var user_agent = getParam("user_agent")
        if( user_agent && user_agent == "mal-ios-app" || user_agent == "mal-android-app") {
          axios.put(`/gdpr/ok/20211022.json`)
          axios.post("/cookie/set", {key: "grecaptcha-badge-hidden", value: 1, expires: 3650})
          return
        }
        this.isShown = true
      },
      close() {
        $("body").css({paddingBottom: 0})
        this.isShown = false
        // OK済みをCookieに保存&ログインユーザはlog記録するエンドポイント（サーバでsetCookieしないとSafariでexpireが7日に制限されるため）
        axios.put(`/gdpr/ok/20211022.json`)
      },
      showUpdates() {
        if ($('.modal-fa-caret-down').length) {
          $('.modal-fa-caret-down').attr('class', 'fas fa-caret-up modal-fa-caret-up ml-b va-m fs-l');
        } else {
          $('.modal-fa-caret-up').attr('class', 'fas fa-caret-down modal-fa-caret-down ml-b va-m fs-l');
        }
        $('.policy-updates').slideToggle(500);
      }
    }
  }
</script>
