const $ = window.$;
const _ = window._;

import Cookies from "js-cookie";
import Editor from "../../../common/modules/editor";
import Modal from "../../../common/modules/modal";
import Toast from "../../../common/modules/toast";
import Tipmenu from "../../../common/modules/tipmenu";
import GtmJsEvent from "../../../common/modules/gtm/jsEvent";
import {number_format, word_replies} from "../../../common/modules/string";

const ERROR_CODE_BLOCKED = 91;
const MESSAGE_TYPE_SELF = 1;
const MESSAGE_TYPE_FRIEND = 2;
const MESSAGE_TYPE_OTHER = 3;
const TOPIC_LAYOUT_COOKIE_KEY = 'forum_topic_layout';
const TOPIC_NAVBAR_AUTOHIDE_THRESHOLD = 3;
const touchEventName = (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) ? 'touchend' : 'click';

$(function () {
  jQuery.fn.extend({
    // メッセージリストにメッセージなどを追加する
    // メッセージ以外の部品もこのメソッドを用いて追加すること
    insertMessage: function ($data, $loadmore) {
      const $replies = $(this);
      const $replyform = $replies.children('.reply-container');
      // loadmoreを経由した読み込みの場合、必ずloadmoreの直前に詰んでいく（下方向）
      if ($loadmore) {
        $loadmore.before($data);
        return;
      }

      // リプライが存在しない場合
      // メッセージを下方向に追加する場合（Oldest, Default）
      if ($('.message', $replies).length == 0 ||
        $replies.attr('data-direction') != 'top') {
        // リプライコンテナの上側に挿入する
        if ($replyform.length > 0) {
          $replyform.before($data);
        }
        // リプライガイドが存在しない場合は、エリアの末尾に挿入する
        else {
          $replies.append($data);
        }
      }
      // メッセージを上方向に追加する場合（Newest）
      else {
        // MEMO:
        // 子メッセージは現在Newestでソートされる可能性がないため実装していない
        // Newest実装時はリプライコンテナの場所を含めて調整する必要がある
        $replies.prepend($data);
      }
    },
    // 子メッセージリスト内の最終投稿idを取得する
    // ただしloadmoreによって折りたたまれているidは除外される
    getLastMessageId: function () {
      const $replies = $(this);
      const search_key = ($replies.attr('data-direction') == 'top') ? 'first' : 'last';
      const $message = $replies.children(`div.message:${search_key}`);
      // メッセージが存在する場合はidを返却する
      if ($message.length > 0) {
        return $message.attr('data-id');
      }
      // 親idを返却する
      return $replies.attr('data-id');
    },
    // メッセージ内のrepliedtoが不要な場合は要素を削除する
    checkReplied: function () {
      const $message = $(this);
      // 親メッセージの場合はチェックしない
      if ($message.attr('data-parent') == 0) {
        return;
      }
      const $prev = $message.prev('div.message');
      const $replied = $('div.replied', $message);
      // 以下の場合はdiv.repliedを表示しない
      // - 前のリプライが存在しない（1番目のリプライor不備時の判定）
      // - 親メッセージへのリプライ
      // - 直近メッセージへのリプライ（子ポストは常に時系列昇順のためprev判定）
      if ($prev.length < 1 ||
        $message.attr('data-parent') == $replied.attr('data-id') ||
        $prev.attr('data-id') == $replied.attr('data-id')) {
        $replied.remove();
      }
    },
    // loadmoreのテキスト表記を更新する
    // loadmore自体が不要な場合は削除する（読み込むものがない）
    updateLoadmoreText: function () {
      const $loadmore = $(this);
      const count = $loadmore.attrAuto('data-count');
      // これ以上読み込むものがない場合はloadmoreを削除する
      if (count < 1) {
        $loadmore.remove();
        return;
      }
      // Loadmoreテキストを更新する
      $loadmore.find('.caption').text(`Load ${number_format(count)} more ${word_replies(count)}`);
      return $loadmore;
    },
    // メッセージ表示領域に指定されたデータを当てはめる
    // メッセージテンプレートは #template_reply を使用する
    buildThreadMessage: function (data, replied) {
      const $message = $(this);
      const disableReply = (
        // forum_topics.topic_locked
        $('#topicLocked').val() == 1 ||
        // ForumConstant::BOARD_ID_FORUM_GAMES
        $('#boardId').val() == 9 ||
        data.deleted ||
        data.removed_user
      );
      $message.attr('data-id', data.id);
      $message.attr('data-parent', data.parent_id);
      // 自身の投稿
      if (data.created && data.created.self) {
        $message.attr('data-message-type', MESSAGE_TYPE_SELF);
      }
      // 友だちの投稿
      else if (data.created && data.created.friend) {
        $message.attr('data-message-type', MESSAGE_TYPE_FRIEND);
      }
      // 他人の投稿（または削除済）
      else {
        $message.attr('data-message-type', MESSAGE_TYPE_OTHER);
      }
      if (data.deleted) {
        $message.addClass('deleted');
      }
      // ユーザが設定されている場合
      if (data.created.name) {
        $message.attr('data-user', data.created.name);
        $('.avatar .forum-icon', $message).attr('href', data.created.url);
        if ($('#showAvatar').val() == 1) {
          if (data.created.avatar) {
            $('.avatar .forum-icon img', $message).attr('src', data.created.avatar);
          }
          $('.avatar .forum-icon', $message).attr('rel', data.created.name);
          $('.avatar .forum-icon', $message).addClass('js-mal-profile-popup');
        } else {
          $('.avatar', $message).remove();
        }
        $('.js-message-ignore', $message).text(data.created.name);
        $('.content .user .name', $message).attr('rel', data.created.name);
        $('.content .user .name', $message).addClass('js-mal-profile-popup');
        $('.content .user .name a', $message).text(data.created.name);
        $('.content .user .name a', $message).attr('href', data.created.url);
        if (data.created.sectitle) {
          $('.content .user .mods-title', $message).addClass(`mal-bg-${data.created.secstr}`);
          $('.content .user .mods-title', $message).attr('rel', data.created.secstr);
          $('.content .user .mods-title', $message).text(data.created.sectitle);
        } else {
          $('.content .user .mods-title', $message).remove();
        }
        if (data.created.title) {
          $('.content .user .custom-title', $message).text(data.created.title);
        } else {
          $('.content .user .custom-title', $message).remove();
        }
      }
      // ユーザが設定されていない場合（削除済ユーザ、削除済ポスト）
      else {
        $('.avatar .forum-icon', $message).removeAttr('href');
        $('.avatar .forum-icon', $message).removeAttr('rel');
        $('.avatar .forum-icon', $message).removeClass('js-mal-profile-popup ga-click');
        $('.content .user .name', $message).remove();
        $('.content .user .mods-title', $message).remove();
        $('.content .user .custom-title', $message).remove();
        $('.js-message-ignore', $message).remove();
      }
      if (data.ignored) {
        $message.addClass('ignored hide-message');
        $('.js-message-ignore', $message).addClass('ignored')
      } else {
        $message.removeClass('ignored hide-message');
        $('.js-message-ignore', $message).removeClass('ignored')
        $('.ignored-container', $message).remove();
      }
      $('.content .user .update', $message).attr('title', data.created.timestamp.date);
      $('.content .user .update', $message).text(data.created.timestamp.ago);
      if (data.body) {
        $('table.body tr td', $message).html(data.body);
      }
      // ロックトピックの場合はadmin以外をdisableにする
      if (disableReply && !$('#topicAdmin').val()) {
        $('.js-thread-reply', $message).prop('disabled', true);
      }
      if (data.removed_user) {
        $message.addClass('removed');
      }
      // 子の数を設定する
      $message.setChildCount($message.getChildCount() + data.childs_count);
      if (!$('#topicAdmin').val()) {
        $('.message-toolbar .right', $message).remove();
      }
      if (data.created.self) {
        $('.js-message-gift', $message).remove();
        $('.divider.div1', $message).remove();
        $('.js-message-ignore', $message).remove();
        $('.js-message-report', $message).remove();
      } else if (!$('#topicAdmin').val()) {
        $('.divider.div2', $message).remove();
        $('.js-quickedit-start', $message).remove();
        $('.js-message-delete', $message).remove();
      }
      if (data.noignore) {
        $('.js-message-ignore', $message).remove();
      }
      if (data.modified && data.modified.timestamp.time > 0) {
        if ($('body').hasClass('sp')) {
          const $modified = $('<div class="modified"></div>');
          $modified.append(`<span class="moduser">${data.modified.name}</span>`);
          $modified.append(`<span class="modtime" title="${data.modified.timestamp.date}">${data.modified.timestamp.ago}</span>`);
          $('table.body', $message).after($modified);
        } else {
          const $modified = $(`<div class="item modified" title="Modified by ${data.modified.name}, ${data.modified.timestamp.ago}"></div>`);
          $('.content .user .update', $message).after($modified);
        }
      }

      if (data.created.is_supporter) {
        if ($('body').hasClass('sp')){
          $('.content .user .row:first', $message).append($('#supporter_badge_sp').html());
        } else {
          $('.content .user', $message).append($('#supporter_badge').html());
        }
      }

      // 未ログインの場合は大半のメニューを隠す
      if (!$('#memId').val()) {
        $('.js-thread-reply', $message).remove();
        $('.js-message-gift', $message).remove();
        $('.js-thread-ellipsis', $message).remove();
        $('.js-message-ignore', $message).remove();
        $('.js-message-report', $message).remove();
        $('.js-quickedit-start', $message).remove();
        $('.js-message-delete', $message).remove();
      }
      // リプライ元を表示する
      if (replied) {
        const $replied = $($('#template_replied').html());
        $replied.attr('data-id', replied.id);
        $('div.js-replyto-target', $replied).attr('data-id', replied.id);
        if (replied.created.name) {
          $('div.js-replyto-target', $replied).text(`Reply to ${replied.created.name}`);
        } else {
          $('div.js-replyto-target', $replied).text(`Reply to Removed User`);
        }
        $('div.replied-body', $replied).html(replied.body);
        $('table.body', $message).before($replied);
      }
      return $message;
    },
    // メッセージを画面上から削除する
    deleteMessage: function () {
      const $message = $(this);
      const msgId = $message.attr('data-id');
      const parentId = $message.attr('data-parent');
      const $parent = $(`div.message[data-id=${parentId}]`);

      // リプライがない場合は削除（Timelineの場合もこちら）
      if ($message.attrAuto('data-childs') == 0) {
        $message.slideUp(function(){
          $message.remove();
        });
      }
      // リプライがある場合は削除済を表示
      else {
        $message.addClass('deleted');
        // Ignore|Unignore処理に影響がでないようdata-userを消去しておく
        $message.attr('data-user', null);
        $('.content .body', $message).empty();
        $('.avatar .forum-icon', $message).removeAttr('href');
        $('.avatar .forum-icon', $message).removeAttr('rel');
        $('.avatar .forum-icon', $message).removeClass('js-mal-profile-popup ga-click');
        $('.avatar .forum-icon img', $message).attr('src', '/images/kaomoji_mal_white.png');
        $('.content .user .name', $message).remove();
        $('.content .user .mods-title', $message).remove();
        $('.content .user .custom-title', $message).remove();
        $('.js-message-ignore', $message).remove();
        $('.js-message-gift', $message).remove();
        $('.js-message-report', $message).remove();
        $('.js-quickedit-start', $message).remove();
        $('.js-message-delete', $message).remove();
        $('.js-thread-reply', $message).prop('disabled', true);
      }

      // 削除されたのが子投稿の場合
      if ($parent.length > 0) {
        $parent.setChildCount($parent.getChildCount() - 1);
        // 子の投稿がなくなった場合
        if ($parent.attrAuto('data-childs') == 0) {
          // リプライ一覧を削除する
          $(`div.replies[data-id=${parentId}]`).slideUp('normal', function () {
            $(`div.replies[data-id=${parentId}]`).remove();
            $parent.attr('data-replies-open', 0);
            $parent.attr('data-replies-loaded', 0);
          });
          // 親スレッドが削除済の場合は非表示にする
          if ($parent.hasClass('deleted')) {
            $parent.slideUp();
          }
        }
      }
      // 削除されたのが親投稿の場合
      else {
        const $replies = $(`div.replies[data-id=${msgId}]`);
        $replies.slideUp('normal', function () {
          $message.attr('data-replies-open', 0);
          $message.attr('data-replies-loaded', 0);
          $replies.remove();
        });
      }
      return $message;
    },
    // メッセージの子カウントを取得する
    getChildCount: function () {
      const $message = $(this);
      return $message.attrAuto('data-childs');
    },
    // メッセージの子カウントを更新し、関連ボタンの表記を変更する
    setChildCount: function (counts) {
      const $message = $(this);
      // 子が存在しない場合はボタンを除去する
      if (counts <= 0) {
        $message.attr('data-childs', 0);
        $message.find('.js-thread-childs').remove();
        return;
      }
      // 件数を更新する
      $message.attr('data-childs', counts);
      // 開閉ボタンを生成する
      if ($message.find('.js-thread-childs').length == 0) {
        $message.find('div.toolbar.left').prepend('<button class="mal-btn secondary outline noborder js-thread-childs pressed">1 reply</button>');
        return;
      }
      // 開閉ボタンテキストを更新する
      $message.find('.js-thread-childs').text(`${number_format(counts)} ${word_replies(counts)}`);
    },
    // メッセージ領域<div.message>から関連するリプライ枠を取得する
    // リプライ枠がない場合は新規生成して返却する
    // 返却されるリプライ枠は非表示されている場合があるため、適宜表示すること
    getRelatedReplies: function () {
      const $container = $(this);
      const msgId = $container.attr('data-id');
      const username = $container.attr('data-user');
      const $message = $(`div.message[data-id=${msgId}]`);
      const disableReply = (
        // forum_topics.topic_locked
        $('#topicLocked').val() == 1 ||
        // ForumConstant::BOARD_ID_FORUM_GAMES
        $('#boardId').val() == 9 ||
        !$('#memId').val() ||
        $message.hasClass('deleted') ||
        $message.hasClass('removed')
      );
      // メッセージではない場合
      if (!$container.hasClass('message') || !msgId) {
        return $('div.replies[data-id=0]');
      }
      // 子メッセージの場合は自分が属するrepliesを返却する
      if ($container.attr('data-parent') > 0) {
        return $container.closest('div.replies');
      }
      // 既に存在する場合はそのまま返却する
      if ($(`div.replies[data-id=${msgId}]`).length > 0) {
        return $(`div.replies[data-id=${msgId}]`);
      }
      // 新しくrepliesを作成する
      const $replies = $($('#template_replies').html());
      $replies.attr('data-id', msgId);
      $container.after($replies);
      $container.attr('data-replies-open', 1);
      $('.js-thread-childs', $container).addClass('pressed');
      // loadmoreを追加する
      $replies.appendLoadmore(msgId, msgId, 0, $container.attr('data-childs'));
      // リプライガイドエリアを追加する
      const $replyguide = $($('#template_replyguide').html());
      $replyguide.find('.js-replyguide-reply').text(`Reply to ${username}'s conversation`);
      // リプライできない場合はガイドを削除する（エリア自体は残す）
      if (disableReply) {
        $replyguide.find('.mal-navbar.replyguide').remove();
      }
      $replies.insertMessage($replyguide);
      return $replies;
    },
    // loadmoreを追加する
    appendLoadmore: function (parent, lastId, maxId, childs) {
      const $replies = $(this);
      const $loadmore = $($('#template_loadmore').html());
      $loadmore.attr('data-parent', parent);
      $loadmore.attr('data-last-id', lastId);
      $loadmore.attr('data-max-id', maxId);
      $loadmore.attr('data-count', childs);
      $replies.insertMessage($loadmore);
      $loadmore.updateLoadmoreText();
      return $loadmore;
    },
    // リプライリスト内のリプライボックスを取得する
    // 存在しない場合は新規追加して返却する
    // ただしリプライボックスはいずれも非表示状態のため、表示処理が必要
    getThreadReplybox: function () {
      const $replies = $(this);
      const $replyform = $replies.children('div.reply-container');
      let $replybox = $('.topic-reply-box', $replies);
      if ($replybox.length == 0) {
        $replybox = $($('#template_replybox').html());
        $replybox.hide();
        $replyform.append($replybox);
      }
      return $replybox;
    },
    // メッセージ内のリプライボックスを取得する
    // 存在しない場合は新規追加して返却する
    getTimelineReplybox: function () {
      const $container = $(this);
      const msgId = $container.attr('data-id');
      let $replybox;
      // メッセージではない場合
      if (!$container.hasClass('message') ||
        !msgId ||
        $('.js-timeline-reply', $container).length == 0) {
        return $('div.topic-reply-box[rel=topic-top]');
      }
      $replybox = $('div.topic-reply-box', $container);
      if ($replybox.length > 0) {
        return $replybox;
      }
      $replybox = $($('#template_replybox').html());
      $replybox.hide();
      if (!$('body').hasClass('sp')) {
        $('div.postActions', $container).after($replybox);
      } else {
        $('div.message-container', $container).after($replybox);
      }
      return $replybox;
    },
    // テキストエディタに文字を追加する
    // BBCodeエディタに対応するためメソッド化している
    insertEditorText: function (text) {
      const $textarea = $(this);
      if (text === 'undefined' || text == null || text == '') return;
      if (!$textarea.is('textarea')) return;
      const $editor = Editor.instance($textarea);
      if ($editor) {
        $editor.insert(text);
      } else {
        $textarea.val($textarea.val() + text);
      }
    },
    // テキストエディタの先頭にメンションを追加する
    // すでにメンションが存在する場合は、指定されたユーザ名に入れ替える
    replaceEditorMention: function (username) {
      const $textarea = $(this);
      const $editor = Editor.instance($textarea);
      if (username === 'undefined' || username == null || username == '') return;
      if (!$textarea.is('textarea')) return;
      let text = $textarea.val();
      // メンションから始まる場合は先頭のメンションを削除する
      if (text.startsWith('@')) {
        text = text.replace(/^@[A-Za-z0-9_-]+ /, '');
      }
      // メンションを追加する
      text = `@${username} ${text}`;
      if ($editor) {
        $editor.val(text);
      }
      else {
        $textarea.val(text);
      }
    },
    // テキストエディタ内のテキストを空にする
    clearEditorText: function () {
      const $textarea = $(this);
      Editor.val($textarea, '');
    },
    // Conversation viewでリプライを開始する
    startThreadReply: function(username, insertText) {
      // Locked topic
      if ($('#topicLocked').val() == 1 && !$('#topicAdmin').val()) {
        return;
      }
      // 未ログインの場合は処理しない
      if (!$('#memId').val()) {
        return;
      }
      const $container = $(this);
      const $replies = $container.getRelatedReplies();
      const $replybox = $replies.getThreadReplybox();
      $replies.slideDown('normal', function () {
        $('.js-thread-childs', $container).addClass('pressed');
        $container.attr('data-replies-open', 1);
        const msgId = $container.attr('data-id');
        const $parentid = $('.topic-reply-parent-id', $replybox);
        const $textarea = $('.topic-reply-text', $replybox);
        Editor.attach($textarea);
        $parentid.val(msgId);
        $replybox.openReplybox(function(){
          $textarea.replaceEditorMention(username);
          $textarea.insertEditorText(insertText);
          Editor.focus($textarea);
        });
      });
    },
    // Classic viewでリプライを開始する
    startTimelineReply: function(username, insertText) {
      // Locked topic
      if ($('#topicLocked').val() == 1 && !$('#topicAdmin').val()) {
        return;
      }
      // 未ログインの場合は処理しない
      if (!$('#memId').val()) {
        return;
      }
      const $container = $(this);
      const $replybox = $container.getTimelineReplybox();
      const msgId = $container.attr('data-id');
      const $parentid = $('.topic-reply-parent-id', $replybox);
      const $textarea = $('.topic-reply-text', $replybox);
      Editor.attach($textarea);
      $parentid.val(msgId);
      $replybox.openReplybox(function(){
        $textarea.replaceEditorMention(username);
        $textarea.insertEditorText(insertText);
        Editor.focus($textarea);
      });
    },
    // Classic viewでクオートを開始する
    startTimelineQuote: function() {
      // Locked topic
      if ($('#topicLocked').val() == 1 && !$('#topicAdmin').val()) {
        return;
      }
      // 未ログインの場合は処理しない
      if (!$('#memId').val()) {
        return;
      }
      const $container = $(this);
      const $button = $('.js-timeline-quote', $container);
      const $replybox = $(`.topic-reply-box[rel=topic-bottom]`);
      const $textarea = $('.topic-reply-text', $replybox);
      const msgId = $container.attr('data-id');
      const username = $container.attr('data-user');
      $button.prop('disabled', true);

      $.ajax({
        type: "POST",
        url: "/includes/quotetext.php",
        data: `msgid=${msgId}`,
        cache: false,
        timeout: 10000,
        success: function (data) {
          $button.prop('disabled', false);
          // 下部バーを常に表示
          $('.topic-reply-bar.hide').removeClass('hide');
          const decoded_data = $("<div/>").html(data).text();
          const quoted_text = `[quote=${username} message=${msgId}]${decoded_data}[/quote]`;
          Editor.attach($textarea);
          $replybox.openReplybox(function(){
            $textarea.insertEditorText(quoted_text);
            Editor.focus($textarea);
          });
        },
        error : function (XMLHttpRequest, textStatus, errorThrown) {
          Modal.alert('There was an error posting, please try again.');
          $button.prop('disabled', false);
        },
      });
    },
    // リプライボックスを開く
    openReplybox: function(callback) {
      const $replybox = $(this);
      const myrel = $replybox.attr('rel');
      if (!callback) callback = function(){};
      // リプライガイドを閉じる
      $replybox.hideReplyGuide();
      // Timeline message reply
      if ($replybox.hasClass('in-timeline')) {
        $('.js-timeline-reply', $replybox).addClass('pressed');
      }
      // Thread message reply
      else if ($replybox.hasClass('in-thread')) {
        $('.js-thread-reply', $replybox).addClass('pressed');
      }
      // top|bottom reply
      else {
        $(`.js-reply-start[rel=${myrel}]`).addClass('pressed');
      }
      // リプライボックスを開いてスクロールする
      Promise.all([
        $replybox.slideDown('normal').promise(),
        $replybox.scrollto(),
      ]).then(callback);
    },
    // リプライボックスを閉じる
    closeReplybox: function(callback) {
      const $replybox = $(this);
      const myrel = $replybox.attr('rel');
      if (!callback) callback = function(){};
      // リプライボックスを閉じる
      $replybox.slideUp('normal', callback);
      // リプライガイドを開く
      $replybox.showReplyGuide();
      // Timeline message reply
      if ($replybox.hasClass('in-timeline')) {
        $('.js-timeline-reply', $replybox).removeClass('pressed');
        return;
      }
      // Thread message reply
      if ($replybox.hasClass('in-thread')) {
        $('.js-thread-reply', $replybox).removeClass('pressed');
        return;
      }
      // top|bottom reply
      $(`.js-reply-start[rel=${myrel}]`).removeClass('pressed');
    },
    // スクロールする
    scrollto: function() {
      const $target = $(this);
      return new Promise((resolve) => {
        $target.scrollintoview({
          duration: 'normal',
          easing: 'swing',
          complete: function() {
            return resolve(true);
          }
        });
      });
    },
    // リプライガイドを表示する
    showReplyGuide: function() {
      const $replybox = $(this);
      const $replies = $replybox.closest('div.replies');
      if (!$replybox.hasClass('in-thread')) return;
      const msgId = $replies.attr('data-id');
      const $parent = $(`div.message[data-id=${msgId}]`);
      // 子メッセージがない場合はガイドを表示せず削除する
      if ($parent.attrAuto('data-childs') == 0) {
        $replies.slideUp('normal', function () {
          $parent.attr('data-replies-open', 0);
          $parent.attr('data-replies-loaded', 0);
          $replies.remove();
        });
        return;
      }
      $('.mal-navbar.replyguide', $replies).slideDown();
    },
    // リプライガイドを隠す
    hideReplyGuide: function() {
      const $replybox = $(this);
      const $replies = $replybox.closest('div.replies');
      if (!$replybox.hasClass('in-thread')) return;
      $('.mal-navbar.replyguide', $replies).slideUp();
    },
    // 対象メッセージをハイライト表示する
    highlight: function () {
      const $message = $(this);
      $message.removeClass('highlight').delay(100).queue(function(){
        $(this).addClass('highlight').dequeue();
      });
    },
    // 対象メッセージにスクロールする
    scrollToMessage: function () {
      const $message = $(this);
      let myScrollTop = $message.position().top;
      // SPの場合はヘッダー分スクロール位置をあげる
      if ($('body').hasClass('sp') && $('#header').length > 0) {
        myScrollTop = myScrollTop - $('#header').height() - 16;
      }
      $('html,body').animate({scrollTop:myScrollTop});
    },
    // 属性データを取得するためのヘルパー関数
    // 文字列→適切なデータ型に変換して返却する
    // 現在使いそうな型のみ判定している
    attrAuto: function (attrname) {
      const data = $(this).attr(attrname);
      if (!data) return null;
      if (!isNaN(data)) return parseInt(data);
      if (data.toLowerCase() === 'true') return true;
      if (data.toLowerCase() === 'false') return false;
      return data;
    },
  });

  // スレッドビュー用の処理
  if ($('.forum.thread').length > 0) {
    // 自動ロードを設定する
    const $loadmore_autoload = $('.js-thread-loadmore.autoload');
    if ($loadmore_autoload.length > 0) {
      $(window).on('scroll.loadmore-autoload', function () {
        // 自動ロードエレメントが除去された場合はスキップする
        if ($loadmore_autoload.length < 1) {
          return;
        }
        const wbottom = $(window).scrollTop() + $(window).innerHeight();
        if ($loadmore_autoload.position().top > wbottom + 100) {
          return;
        }
        if (window.MAL.loadmore_autoload) {
          clearTimeout(window.MAL.loadmore_autoload);
          window.MAL.loadmore_autoload = undefined;
        }
        window.MAL.loadmore_autoload = setTimeout(function () {
          $loadmore_autoload.trigger('click');
        }, 50);
      });
    }
  }

  $(window).on('scroll.show-replies', function () {
    const $window = $(this);
    const $container = $('.topic-reply-container[rel=topic-bottom]');
    if ($container.hasClass('hide')) return;
    if ($window.scrollTop() > 0) {
      $container.fadeIn();
    } else {
      $container.fadeOut();
    }
  });

  // ------------------------------------------------------------
  // Tipmenu
  // ------------------------------------------------------------

  Tipmenu.initiailze();
  if ($('#memId').val() > 0 &&
    $('#topicLocked').val() != 1 &&
    $('#boardId').val() != 9) {
    Tipmenu.add('<a href="javascript:void(0);" class="js-quickquote-reply"><i class="fa-solid fa-quote-left fa-fw mr4"></i>Quote Reply</a>');
  }
  Tipmenu.add('<a href="javascript:void(0);" class="js-quickquote-copy"><i class="fa-solid fa-copy fa-fw mr4"></i>Copy</a>');

  $(document).on('selectionchange', (e) => {
    Tipmenu.hide();
    const selection = document.getSelection();
    const parentDiv = getClosestParentDiv(selection);
    if (!parentDiv) return;
    if (parentDiv.hasClass('deleted') || parentDiv.hasClass('removed')) return;
    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();
    Tipmenu.position(
      ($(window).scrollTop() + rect.bottom + 15),
      ((rect.left + rect.right - Tipmenu.width()) / 2)
    );
    Tipmenu.show();
  });

  // ------------------------------------------------------------
  // replybox
  // ------------------------------------------------------------

  // Replybox - start
  $('body.page-forum').on('click', '.js-reply-start', function () {
    // 未ログインの場合は処理しない
    if (!$('#memId').val()) return;
    const myrel = $(this).attr('rel');
    const $replybox = $(`.topic-reply-box[rel=${myrel}]`);
    if ($replybox.is(':visible')) {
      $replybox.closeReplybox();
    } else {
      const $textarea = $('.topic-reply-text', $replybox);
      Editor.attach($textarea);
      $replybox.openReplybox(function(){
        Editor.focus($textarea);
      });
    }
  });

  // Replybox - preview
  $('body.page-forum').on('click', '.js-reply-preview', function () {
    const $container = $(this).closest('.topic-reply-box');
    const $parentid = $('.topic-reply-parent-id', $container);
    const $textarea = $('.topic-reply-text', $container);
    const topicId = $('#topicId').val();
    $('#replyform').attr('action', `/forum/?action=message&topic_id=${topicId}`);
    $('#replyform_action_type').val('preview');
    $('#replyform_parent_id').val($parentid.val());
    $('#replyform_msg_text').val($textarea.val());
    $('#replyform').submit();
  });

  // Replybox - cancel
  $('body.page-forum').on('click', '.js-reply-cancel', function () {
    const $replybox = $(this).closest('.topic-reply-box');
    if ($replybox.hasClass('in-thread') ||
      $replybox.hasClass('in-timeline')) {
      $replybox.closeReplybox();
      return;
    }
    const myrel = $replybox.attr('rel');
    $(`.js-reply-start[rel=${myrel}]`).trigger('click');
  });

  // Replybox - bbcode
  $('body.page-forum').on('click', '.js-reply-bbcode', function () {
    window.open("/info.php?go=bbcode","bbcode","menubar=no,scrollbars=yes,status=no,width=600,height=700");
  });

  // Replybox - submit - timelineモード
  $('body.page-forum').on('click', '.js-timeline-reply-submit', function () {
    // GA4クリックイベントを送信する
    GtmJsEvent.send('forum-reply-submit-timeline');

    const $button = $(this);
    const $replybox = $button.closest('.topic-reply-box');
    const $indicator = $('.topic-reply-indicator', $replybox);
    const $parentid = $('.topic-reply-parent-id', $replybox);
    const $textarea = $('.topic-reply-text', $replybox);
    const topicId = $('#topicId').val();
    $button.prop('disabled', true);
    $indicator.addClass('show');
    $.ajax({
      type    : 'POST',
      url     : '/includes/ajax.inc.php?t=82',
      cache   : false,
      data    : {
        topicId: topicId,
        parentId: $parentid.val(),
        messageText: $textarea.val(),
        totalReplies: $('#totalReplies').val(),
      },
      dataType: 'json',
      timeout: 10000,
      success : function (data__safe) {
        $button.prop('disabled', false);
        $indicator.removeClass('show');

        /* post error */
        if (data__safe.html == null) {
          if (data__safe.errors != null) {
            // Banになったらリロードして通知する
            if (data__safe.errors[0].error == 'banned') {
              location.reload();
              return;
            }
            Modal.alert(data__safe.errors[0].message);
          }
          else {
            Modal.alert('There was an error posting, please try again.');
          }
          return;
        }

        $('.messages .mal-alert.moreposts').remove();
        if (data__safe.moreposts) {
          const $moreposts = $('<div class="mal-alert primary moreposts"></div>');
          if (data__safe.moreposts > 1) {
            $moreposts.append(`${data__safe.moreposts} posts have been added since your last page refresh. `);
          } else {
            $moreposts.append(`${data__safe.moreposts} post has been added since your last page refresh. `);
          }
          $moreposts.append(`<a href="?topicid=${topicId}&goto=newpost">Go to new post</a>`);
          $('.messages').append($moreposts);
        }

        // データ挿入とjs発火設定
        $('.messages').append(data__safe.html);
        // 編集終了
        $parentid.val('');
        $textarea.clearEditorText();
        $replybox.closeReplybox();
        // 下部バー表示判定
        checkShowReplyBar();
        // スクロール
        $(`#msg${data__safe.msg_id}`).scrollto().then();
      },
      error : function (XMLHttpRequest, textStatus, errorThrown) {
        Modal.alert('There was an error posting, please try again.');
        $button.prop('disabled', false);
        $indicator.removeClass('show');
      },
    });
  });

  // Replybox - submit - threadモード
  $('body.page-forum').on('click', '.js-thread-reply-submit', function () {
    // GA4クリックイベントを送信する
    GtmJsEvent.send('forum-reply-submit-thread');

    const $button = $(this);
    const $replybox = $button.closest('.topic-reply-box');
    const $replies = ($replybox.closest('div.replies').length > 0) ? $replybox.closest('div.replies') : $('div.replies[data-id=0]');
    const $indicator = $('.topic-reply-indicator', $replybox);
    const $parentid = $('.topic-reply-parent-id', $replybox);
    const $textarea = $('.topic-reply-text', $replybox);
    const topicId = $('#topicId').val();
    $button.prop('disabled', true);
    $indicator.addClass('show');
    $.ajax({
      type    : 'POST',
      url     : `/forum/${topicId}/reply`,
      cache   : false,
      data    : {
        topicId: topicId,
        parentId: $parentid.val(),
        messageText: $textarea.val(),
        lastId: $replies.getLastMessageId(),
      },
      dataType: 'json',
      timeout: 10000,
      success : function (data) {
        $button.prop('disabled', false);
        $indicator.removeClass('show');

        /* post error */
        if (data.error) {
          // Banになったらリロードして通知する
          if (data.error.code == ERROR_CODE_BLOCKED) {
            location.reload();
            return;
          }
          Modal.alert(data.error.message, data.error.code);
          return;
        }

        // メッセージ表示設定
        const $message = $($('#template_reply').html());
        const $parent = $(`div.message[data-id=${data.message.parent_id}]`);
        $message.buildThreadMessage(data.message, data.replied[data.message.reply_to]);
        if (data.message.parent_id > 0) {
          // 子メッセージの場合は不要な要素を削除する
          $('.js-thread-childs', $message).remove();
          // リプライ数を+1する
          $parent.setChildCount($parent.getChildCount() + 1);
        }
        // 既存のloadmoreを削除する
        const $loadmore = $replies.children('.js-thread-loadmore[data-max-id=0]')
        if ($loadmore.length > 0) {
          // 削除した分の件数を差し引いておく（あとから再びloadmoreを追加するため）
          $parent.setChildCount($parent.getChildCount() - $loadmore.attrAuto('data-count'));
          $loadmore.remove();
        }
        // 読込のない投稿がある場合はloadmoreを追加する
        if (data.before) {
          $replies.appendLoadmore(
            data.message.parent_id,
            data.before.last,
            data.before.max,
            data.before.count
          );
          // 読込前の件数を子カウントに追加する
          $parent.setChildCount($parent.getChildCount() + data.before.count);
        }
        // メッセージを追加する
        $replies.insertMessage($message);
        $message.checkReplied();
        $message.highlight();
        // 自動ローディングを停止する（スクロール後にロードされるのを抑止するため）
        $('.js-thread-loadmore.autoload').removeClass('autoload');
        $(window).off('scroll.loadmore-autoload');
        // 総リプライ数を更新
        let totalReplies = parseInt($('#totalReplies').val()) + 1;
        $('#totalReplies').val(totalReplies);
        $('#totalRepliesShow').text(number_format(totalReplies));
        // 編集終了
        $parentid.val('0');
        $textarea.clearEditorText();
        $replybox.closeReplybox(function(){
          if (!$replybox.hasClass('fixed')) {
            $replybox.remove();
          }
        });
        // 下部バー表示判定
        checkShowReplyBar();
        // スクロール
        $message.scrollto().then();
      },
      error : function (XMLHttpRequest, textStatus, errorThrown) {
        Modal.alert('There was an error posting, please try again.');
        $button.prop('disabled', false);
        $indicator.removeClass('show');
      },
    });
  });

  // ------------------------------------------------------------
  // Timeline/Thread 両モードで利用する処理
  // ------------------------------------------------------------

  $('.js-topic-top').on('click', function () {
    $('html,body').animate({ scrollTop: 0 });
  });

  $('.js-topic-poll-vote').on('click', function () {
    const optionId = $(this).attr('data-id');
    const topicId = $('#topicId').val();
    $('<i class="fa-solid fa-spinner fa-spin mr4"></i>').prependTo(`.js-topic-poll-vote[data-id=${optionId}]`);
    $('.js-topic-poll-vote').prop('disabled', true);
    $.ajax({
      url: `/forum/${topicId}/vote/option`,
      type: 'POST',
      data: {option_id: optionId},
      datatype: 'json',
      timeout: 10000,
      cache: false,
      success : function (data) {
        if (data.error) {
          Modal.alert(data.error.message, data.error.code);
          $(`.js-topic-poll-vote[data-id=${optionId}] i`).remove();
          $('.js-topic-poll-vote').prop('disabled', false);
          return;
        }
        location.reload();
      },
      error : function (XMLHttpRequest, textStatus, errorThrown) {
        Modal.alert('There was an error vote, please try again.');
        $(`.js-topic-poll-vote[data-id=${optionId}] i`).remove();
        $('.js-topic-poll-vote').prop('disabled', false);
      }
    });
  });

  $('.js-topic-poll-cancel').on('click', function () {
    const topicId = $('#topicId').val();
    $('<i class="fa-solid fa-spinner fa-spin mr4"></i>').prependTo(`.js-topic-poll-cancel`);
    $('.js-topic-poll-cancel').prop('disabled', true);
    $.ajax({
      url: `/forum/${topicId}/vote/cancel`,
      type: 'POST',
      data: {},
      datatype: 'json',
      timeout: 10000,
      cache: false,
      success : function (data) {
        if (data.error) {
          Modal.alert(data.error.message, data.error.code);
          $('.js-topic-poll-cancel i').remove();
          $('.js-topic-poll-cancel').prop('disabled', false);
          return;
        }
        location.reload();
      },
      error : function (XMLHttpRequest, textStatus, errorThrown) {
        Modal.alert('There was an error canceling vote, please try again.');
        $('.js-topic-poll-cancel i').remove();
        $('.js-topic-poll-cancel').prop('disabled', false);
      }
    });
  });

  $('.js-toggle-ignore-topic').on('click', function () {
    const $button = $(this);
    const topicId = $('#topicId').val();
    $('.js-toggle-ignore-topic').prop('disabled', true);
    $('i.checker', $button).removeClass('fa-circle-check');
    $('i.checker', $button).addClass('fa-spinner fa-spin fa-fw mr4');
    $.ajax({
      type: 'POST',
      url: `/forum/${topicId}/toggle-ignore`,
      cache: false,
      data: {},
      dataType: 'json',
      timeout: 10000,
      success: function (data) {
        $('.js-toggle-ignore-topic').prop('disabled', false);
        $('i.checker', $button).removeClass('fa-spinner fa-spin fa-fw mr4');
        if (data.error) {
          Modal.alert(data.error.message, data.error.code);
          return;
        }
        /* Ignored */
        if (data.ignored) {
          $('.js-toggle-ignore-topic').removeClass('secondary');
          $('.js-toggle-ignore-topic').addClass('primary noborder pressed checked');
          $('i.checker', $button).addClass('fa-circle-check fa-fw mr4');
          return;
        }
        /* Not Ignored */
        $('.js-toggle-ignore-topic').removeClass('primary noborder pressed checked');
        $('.js-toggle-ignore-topic').addClass('secondary');
      },
      error: function (XMLHttpRequest, textStatus, errorThrown) {
        $('.js-toggle-ignore-topic').prop('disabled', false);
        $('i.checker', $button).removeClass('fa-spinner fa-spin fa-fw mr4');
        Modal.alert('Something went wrong. Please try again later.');
      },
    });
  });

  $('.js-toggle-watch-topic').on('click', function () {
    const $button = $(this);
    const topicId = $('#topicId').val();
    $('.js-toggle-watch-topic').prop('disabled', true);
    $('i.checker', $button).removeClass('fa-circle-check');
    $('i.checker', $button).addClass('fa-spinner fa-spin fa-fw mr4');
    $.ajax({
      type: 'POST',
      url: `/forum/${topicId}/toggle-watch`,
      cache: false,
      data: {},
      dataType: 'json',
      timeout: 10000,
      success: function (data) {
        $('.js-toggle-watch-topic').prop('disabled', false);
        $('i.checker', $button).removeClass('fa-spinner fa-spin fa-fw mr4');
        if (data.error) {
          Modal.alert(data.error.message, data.error.code);
          return;
        }
        /* Watched */
        if (data.watched) {
          $('.js-toggle-watch-topic').removeClass('secondary');
          $('.js-toggle-watch-topic').addClass('primary noborder pressed checked');
          $('i.checker', $button).addClass('fa-circle-check fa-fw mr4');
          return;
        }
        /* Unwatched */
        $('.js-toggle-watch-topic').removeClass('primary noborder pressed checked');
        $('.js-toggle-watch-topic').addClass('secondary');
      },
      error: function (XMLHttpRequest, textStatus, errorThrown) {
        $('.js-toggle-watch-topic').prop('disabled', false);
        $('.js-toggle-watch-topic i.loading').remove();
        $('i.checker', $button).removeClass('fa-spinner fa-spin fa-fw mr4');
        Modal.alert('Something went wrong. Please try again later.');
      },
    });
  });

  $('.js-topic-report').on('click', function () {
    const topicId = $('#topicId').val();
    const form = $('<form method="get" action="/modules.php"></form>');
    form.append(`<input type="hidden" name="go" value="report">`);
    form.append(`<input type="hidden" name="type" value="forummessage">`);
    form.append(`<input type="hidden" name="id" value="1">`);
    form.append(`<input type="hidden" name="id2" value="${topicId}">`);
    form.appendTo('body');
    form.submit();
  });

  $('.js-topic-edit').on('click', function () {
    const msgId = $(this).attr('data-msg-id');
    location.href = `/forum/?action=message&msgid=${msgId}`;
  });

  $('.js-topic-copylink').on('click', function () {
    const topicId = $('#topicId').val();
    const link = `https://${location.hostname}${location.pathname}?topicid=${topicId}`;
    navigator.clipboard.writeText(link);
    Toast.add('Link copied.', 5000, 'primary');
  });

  $('.js-topic-remove').on('click', function () {
    const topicId = $('#topicId').val();
    const form = $('<form method="POST" action="/forum/?action=removetopic"></form>');
    form.append(`<input type="hidden" name="id" value="${topicId}">`);
    form.appendTo('body');
    form.submit();
  });

  $('.js-topic-lock').on('click', function () {
    const topicId = $('#topicId').val();
    $('.js-topic-lock').removeClass('noborder pressed')
    $('.js-topic-lock').prop('disabled', true);
    $('.js-topic-lock i.checker').remove();
    $('<i class="fa-solid fa-spinner fa-spin fa-fw mr4 loading"></i>').prependTo('.js-topic-lock');

    $.ajax({
      type: 'POST',
      url: `/forum/${topicId}/toggle-lock`,
      data: {},
      timeout: 10000,
      dataType: 'json',
      success : function (data) {
        $('.js-topic-lock').prop('disabled', false);
        $('.js-topic-lock i.loading').remove();
        if (data.error) {
          Modal.alert(data.error.message, data.error.code);
          return;
        }
        if (data.topic_locked == 1) {
          $('<i class="fa-solid fa-circle-check mr4 checker"></i>').prependTo('.js-topic-lock');
          $('.js-topic-lock').addClass('noborder pressed')
        }
        $('#topicLocked').val(data.topic_locked);
        location.reload();
      }
    });
  });

  $('.js-topic-sticky').on('click', function () {
    const topicId = $('#topicId').val();
    $('.js-topic-sticky').removeClass('noborder pressed')
    $('.js-topic-sticky').prop('disabled', true);
    $('.js-topic-sticky i.checker').remove();
    $('<i class="fa-solid fa-spinner fa-spin fa-fw mr4 loading"></i>').prependTo('.js-topic-sticky');
    $.ajax({
      type: 'POST',
      url: `/forum/${topicId}/toggle-sticky`,
      data: {},
      timeout: 10000,
      dataType: 'json',
      success : function (data) {
        $('.js-topic-sticky').prop('disabled', false);
        $('.js-topic-sticky i.loading').remove();
        if (data.error) {
          Modal.alert(data.error.message, data.error.code);
          return;
        }
        if (data.topic_type == 2) {
          $('<i class="fa-solid fa-circle-check mr4 checker"></i>').prependTo('.js-topic-sticky');
          $('.js-topic-sticky').addClass('noborder pressed')
        }
      }
    });
  });

  $('.js-topic-quick-move').on('click', function () {
    const topicId = $('#topicId').val();
    Modal.generate();
    Modal.buildTopicQuickMoveDialog(topicId);
    Modal.show();
  });

  $('.js-topic-move').on('click', function () {
    const topicId = $('#topicId').val();
    const form = $('<form method="POST" action="/forum/?action=movetopic"></form>');
    form.append(`<input type="hidden" name="id" value="${topicId}">`);
    form.appendTo('body');
    form.submit();
  });

  $('.js-topic-merge').on('click', function () {
    const topicId = $('#topicId').val();
    const form = $('<form method="POST" action="/forum/?action=merge"></form>');
    form.append(`<input type="hidden" name="id" value="${topicId}">`);
    form.appendTo('body');
    form.submit();
  });

  $('.js-topic-switch-layout').on('click', function () {
    const currentLayout = $('#topicLayout').val();
    const targetLayout = $(this).attr('rel');
    if (currentLayout == targetLayout) {
      return;
    }
    Cookies.set(TOPIC_LAYOUT_COOKIE_KEY, targetLayout, { expires: 365 });
    location.reload();
  });

  $('.js-thread-sort-order').on('click', function () {
    if ($(this).hasClass('checked')) {
      return;
    }
    $('.btn-thread-sort-order').html($(this).html());
    $('.js-thread-sort-order').removeClass('checked');
    $(this).addClass('checked');
    Cookies.set('forum_topic_order', $(this).attr('rel'), { expires: 365 });
    location.reload();
  });

  // TODO:現時点では未実装機能のため、後ほど仕様を議論すること
  $('.js-thread-filter').on('click', function () {
    const $item = $(this);
    const $parent = $item.closest('.mal-dropdown-outer');
    const $button = $parent.find('.mal-btn-dropdown');
    if ($item.hasClass('checked')) {
      return;
    }
    $('.js-thread-filter').removeClass('checked');
    $item.addClass('checked');
    $button.html($item.attr('data-label'));
    const message_type = $(this).attr('rel');

    $('div.message').each(function(){
      const $container = $(this);
      // 最初のメッセージは何もしない
      if ($container.hasClass('first')) {
        return;
      }
      // 既に隠す処理がされている場合は何もしない
      if ($container.hasClass('hide-message')) {
        return;
      }
      // すべてを表示
      if (message_type == 0) {
        $container.show();
      }
      // 自身のみ表示
      if (message_type == 1) {
        
        if ($container.attrAuto('data-message-type') == 1) {
          $container.show();
        }
        // それ以外の場合
        else {
          // 親ポストの場合は子を探索する
          if ($container.attr('data-parent') == 0) {
            const msg_id = $container.attr('data-id');
            const $replies = $(`div.replies[data-id=${msg_id}]`);
            const $childs = $replies.children('div.message').filter(function(child){
              const $child = $(child);
              return ($child.attrAuto('data-message-type') == 1);
            });
            // 対象の子が存在する場合
            if ($childs.length > 0) {
              $container.show();
              $replies.show();
            }
            // 対象の子が存在しない場合
            else {
              $container.hide();
              $replies.hide();
            }
          }
          // 子ポストの場合は隠す
          else {
            $container.hide();
          }
        }
      }
      // 自身と友だちのみ表示
      if (message_type == 2) {
        // 自身と友だちの場合
        if ($container.attrAuto('data-message-type') == 1 ||
          $container.attrAuto('data-message-type') == 2) {
          $container.show();
        }
        // それ以外の場合
        else {
          // 親ポストの場合は子を探索する
          if ($container.attr('data-parent') == 0) {
            const msg_id = $container.attr('data-id');
            const $replies = $(`div.replies[data-id=${msg_id}]`);
            const $childs = $replies.children('div.message').filter(function(){
              const $child = $(this);
              return (
                $child.attrAuto('data-message-type') == 1 ||
                $child.attrAuto('data-message-type') == 2
              );
            });
            // 対象の子が存在する場合
            if ($childs.length > 0) {
              $container.show();
              $replies.show();
            }
            // 対象の子が存在しない場合
            else {
              $container.hide();
              $replies.hide();
            }
          }
          // 子ポストの場合は隠す
          else {
            $container.hide();
          }
        }
      }
    });
  });

  $('body.page-forum').on('close', 'div.message-edit-container', function () {
    const $quickedit = $(this);
    const $container = $quickedit.closest('div.message');
    const $waiter = $('div.message-edit-waiter', $container);
    const $body = $('table.body', $container);
    $('.js-quickedit-start').removeClass('pressed');
    $waiter.remove();
    $quickedit.slideUp(function(){
      $quickedit.remove();
      $body.show();
    });
  });

  $('body.page-forum').on('click', '.js-quickedit-start', function () {
    const $button = $(this);
    const $container = $button.closest('div.message');
    const $body = $('table.body', $container);
    const topicId = $('#topicId').val();
    const msgId = $container.attr('data-id');

    // 既に表示中のため、閉じる
    if (!$body.is(':visible')) {
      $('div.message-edit-container', $container).trigger('close');
      return;
    }

    // 編集用のコンテナを準備する
    const $waiter = $($('#template_quickedit_waiter').html());
    $body.after($waiter);
    $body.hide();

    $.ajax({
      type: 'POST',
      url: `/forum/${topicId}/quickedit/${msgId}`,
      data: {},
      timeout: 10000,
      dataType: 'json',
      success : function (data) {
        $waiter.remove();
        if (data.error) {
          Modal.alert(data.error.message, data.error.code);
          $editor.trigger('close');
          return;
        }

        const $editor = $($('#template_quickedit').html());
        const $textarea = $('textarea.textarea', $editor);
        $editor.hide();
        $body.after($editor);
        $textarea.val(data.message);
        Editor.attach($textarea);
        $editor.slideDown(function(){
          Editor.focus($textarea);
        });
        $('.js-quickedit-start', $container).addClass('pressed');
      },
      error: function () {
        $waiter.remove();
        Modal.alert('Something went wrong. Please try again later.');
      }
    });
  });

  $('body.page-forum').on('click', '.js-quickedit-submit', function () {
    const $button = $(this);
    const $container = $button.closest('div.message');
    const $body = $('table.body', $container);
    const $body_inner = $('tr td', $body);
    const $editor = $('div.message-edit-container', $container);
    const $textarea = $('textarea.textarea', $editor);
    const topicId = $('#topicId').val();
    const msgId = $container.attr('data-id');

    const $waiter = $($('#template_quickedit_waiter').html());
    $body.after($waiter);
    $body.hide();

    $.ajax({
      type: 'POST',
      url: `/forum/${topicId}/quickedit/${msgId}/submit`,
      cache: false,
      timeout: 10000,
      data: {
        msg_id: msgId,
        msg: $textarea.val(),
      },
      dataType: 'json',
      success : function (data) {
        $waiter.remove();
        if (data.error) {
          // Banになったらリロードして通知する
          if (data.error.code == ERROR_CODE_BLOCKED) {
            location.reload();
            return;
          }
          Modal.alert(data.error.message, data.error.code);
          return;
        }
        $body_inner.html(data.message_html);
        $editor.trigger('close');
      },
      error: function () {
        $waiter.remove();
        Modal.alert('Something went wrong. Please try again later.');
      }
    });
  });

  $('body.page-forum').on('click', '.js-quickedit-preview', function () {
    const $button = $(this);
    const $container = $button.closest('div.message');
    const $editor = $('div.message-edit-container', $container);
    const $textarea = $('textarea.textarea', $editor);
    const topicId = $('#topicId').val();
    const msgId = $container.attr('data-id');
    $('#replyform').attr('action', `/forum/?action=message&topic_id=${topicId}&msgid=${msgId}`);
    $('#replyform_action_type').val('preview');
    $('#replyform_msg_text').val($textarea.val());
    $('#replyform').submit();
  });

  $('body.page-forum').on('click', '.js-quickedit-cancel', function () {
    const $button = $(this);
    const $container = $button.closest('div.message-edit-container');
    $container.trigger('close');
  });

  // Quick quote - reply
  $('.page-forum').on(touchEventName, '.js-quickquote-reply', function () {
    const quoted_text = getQuoteSelectionText();
    if (!quoted_text) return;
    Tipmenu.hide();
    const selection = document.getSelection();
    const $container = getClosestMessageDiv(selection);
    // Timelineモード
    if ($('.forum.timeline').length > 0) {
      $container.startTimelineReply(null, quoted_text);
      return;
    }
    // Threadモードかつ初期ポストへのリプライ
    if (!$container || $('.js-thread-reply', $container).length == 0) {
      const $replybox = $('.topic-reply-box[rel=topic-top]');
      const $textarea = $('.topic-reply-text', $replybox);
      // メッセージidを付加する
      if ($container) {
        const msgId = $container.attr('data-id');
        const $parentid = $('.topic-reply-parent-id', $replybox);
        $parentid.val(msgId);
      }
      Editor.attach($textarea);
      $replybox.openReplybox(function(){
        $textarea.insertEditorText(quoted_text);
        Editor.focus($textarea);
      });
      return;
    }
    // Threadモード
    $container.startThreadReply(null, quoted_text);
  });

  // Quick quote - copy
  $('.page-forum').on(touchEventName, '.js-quickquote-copy', function () {
    const quoted_text = getQuoteSelectionText();
    if (!quoted_text) return;
    navigator.clipboard.writeText(quoted_text);
    Toast.add('Text copied.', 5000, 'primary');
    Tipmenu.hide();
  });

  // Message - show/hide ignored message
  $('.forum').on('click', '.js-message-toggle-ignored', function () {
    const $button = $(this);
    const $container = $button.closest('div.message');
    if ($container.hasClass('hide-message')) {
      $container.removeClass('hide-message');
      $button.addClass('pressed noborder primary').removeClass('secondary').text('Hide ignored post');
    } else {
      $container.addClass('hide-message');
      $button.removeClass('pressed noborder primary').addClass('secondary').text('Show ignored post');
    }
  });

  // Message - copylink
  $('.forum').on('click', '.js-message-copylink', function () {
    const $button = $(this);
    const $container = $button.closest('div.message');
    const topicId = $('#topicId').val();
    const msgId = $container.attr('data-id');
    const url = new URL(`https://${location.hostname}/forum/`);
    url.searchParams.set('goto', 'post');
    url.searchParams.set('topicid', topicId);
    url.searchParams.set('id', msgId);
    navigator.clipboard.writeText(url);
    Toast.add('Link copied.', 5000, 'primary');
  });

  // Message - permlink
  $('.forum').on('click', '.js-message-permlink', function () {
    const $button = $(this);
    const $container = $button.closest('div.message');
    const topicId = $('#topicId').val();
    const msgId = $container.attr('data-id');
    const url = new URL(`https://${location.hostname}/forum/`);
    url.searchParams.set('goto', 'post');
    url.searchParams.set('topicid', topicId);
    url.searchParams.set('id', msgId);
    window.history.pushState({}, '', url);
    $container.highlight();
    $container.scrollToMessage();
    return false;
  });

  // Message - delete
  $('.forum').on('click', '.js-message-delete', function () {
    const $button = $(this);
    const $container = $button.closest('div.message');
    const topicId = $('#topicId').val();
    const msgId = $container.attr('data-id');
    Modal.confirm({
      title: 'Confirm',
      message: 'Are you sure you want to delete this message?',
      confirm: {
        text: 'Yes',
        class: 'danger',
        action: function() {
          $.ajax({
            type: 'POST',
            url: `/forum/${topicId}/quickedit/${msgId}/delete`,
            cache: false,
            data: {},
            timeout: 10000,
            dataType: 'json',
            success : function (data) {
              if (data.error) {
                Modal.alert(data.error.message, data.error.code);
                return;
              }
              $container.deleteMessage();
              // 総リプライ数を更新
              let totalReplies = parseInt($('#totalReplies').val()) - 1;
              if (totalReplies < 0) totalReplies = 0;
              $('#totalReplies').val(totalReplies);
              $('#totalRepliesShow').text(number_format(totalReplies));
            }
          });
        }
      },
      cancel: {
        text: 'No',
        action: function() {}
      }
    });
  });

  $('.forum').on('click', '.js-replyto-view', function () {
    const $button = $(this);
    const $container = $button.closest('div.replied');
    $container.toggleClass('show');
  });

  $('.forum').on('click', '.js-message-mod-delete', function () {
    const $button = $(this);
    const $container = $button.closest('div.message');
    const topicId = $('#topicId').val();
    const msgId = $container.attr('data-id');
    const $deleteYes = $('<button class="yes mal-btn danger outline noborder">yes</button>');
    const $deleteNo = $('<button class="no mal-btn secondary outline noborder">no</button>');
    const $deleteContainer = $('<span class="confirmDelete"></span>');
    $deleteContainer.append('<span class="text"><i class="fa-solid fa-trash-can fa-fw mr4"></i>Are you sure?</span>');
    $deleteContainer.append($deleteYes);
    $deleteContainer.append('<span class="text ml4 mr4">/</span>');
    $deleteContainer.append($deleteNo);

    $deleteYes.on('click', function(){
      $deleteYes.prop('disabled', true);
      $.ajax({
        type: 'POST',
        url: `/forum/${topicId}/quickedit/${msgId}/delete`,
        cache: false,
        data: {},
        timeout: 10000,
        dataType: 'json',
        success : function (data) {
          if (data.error) {
            Modal.alert(data.error.message, data.error.code);
            return;
          }
          $container.deleteMessage();
          // 総リプライ数を更新
          let totalReplies = parseInt($('#totalReplies').val()) - 1;
          if (totalReplies < 0) totalReplies = 0;
          $('#totalReplies').val(totalReplies);
          $('#totalRepliesShow').text(number_format(totalReplies));
        }
      });
    });
    $deleteNo.on('click', function(){
      $deleteContainer.remove();
      $button.show();
    });
    $button.after($deleteContainer);
    $button.hide();
  });

  // Message - ignore | unignore
  $('.forum').on('click', '.js-message-ignore', function () {
    const $button = $(this);
    const $container = $button.closest('div.message');
    const username = $container.attr('data-user');

    // Unignore user処理
    if ($(this).hasClass('ignored')) {
      $.ajax({
        url: `/forum/settings/ignored_users`,
        method: 'DELETE',
        data: {name: username},
        dataType: 'json',
        timeout: 10000,
        success: function (data) {
          // Thread view
          if ($(`div.message[data-user=${username}] .message-container`).length > 0) {
            $(`div.message[data-user=${username}] .message-container`).slideDown('normal', function(){
              $(`div.message[data-user=${username}]`).removeClass('ignored hide-message');
              $(`div.message[data-user=${username}] .js-message-ignore`).removeClass('ignored');
              $(`div.message[data-user=${username}] .ignored-container`).remove();
            });
          }
          // Timeline view
          else {
            $(`div.message[data-user=${username}] .message-wrapper`).slideDown('normal', function(){
              $(`div.message[data-user=${username}]`).removeClass('ignored hide-message');
              $(`div.message[data-user=${username}] .js-message-ignore`).removeClass('ignored');
              $(`div.message[data-user=${username}] .ignored-container`).remove();
            });
          }
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
          Modal.alert('Something went wrong. Please try again later.');
        },
      });
    }
    // Ignore user処理
    else {
      $.ajax({
        type: 'POST',
        url: `/forum/settings/ignored_users`,
        cache: false,
        data: {name: username},
        dataType: 'json',
        timeout: 10000,
        success: function (data) {
          const $ignore = $($('#template_ignore').html());
          $(`div.message[data-user=${username}]`).each(function(){
            const $container = $(this);
            $('.js-message-ignore', $container).addClass('ignored');
            $container.addClass('ignored hide-message');
            $container.prepend($ignore.clone());
          });
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
          Modal.alert('Something went wrong. Please try again later.');
        },
      });
    }
  });

  // Message - gift
  $('.forum').on('click', '.js-message-gift', function () {
    const $button = $(this);
    const $container = $button.closest('div.message');
    const msgId = $container.attr('data-id');
    GtmJsEvent.send('gift-entry-forum', `msgid:${msgId}`)
    location.href = '/membership';
  });

  // Message - report
  $('.forum').on('click', '.js-message-report', function () {
    const $button = $(this);
    const $container = $button.closest('div.message');
    const topicId = $('#topicId').val();
    const msgId = $container.attr('data-id');
    const url = `/modules.php?go=report&type=forummessagenew&id=${msgId}&id2=${topicId}`;
    location.href = url;
  });

  // Replyto
  $('.forum').on('click', '.js-replyto-target', function () {
    const $button = $(this);
    const topicId = $('#topicId').val();
    const msgId = $button.attr('data-id');
    const $message = $(`div.message[data-id=${msgId}]`);
    // ページ内に存在する場合はスクロールする
    if ($message.length > 0 && $message.is(':visible')) {
      $message.highlight();
      $message.scrollToMessage();
      return;
    }
    // ページ内に存在しない場合はリンク先に遷移する
    const url = new URL(`https://${location.hostname}/forum/`);
    url.searchParams.set('goto', 'post');
    url.searchParams.set('topicid', topicId);
    url.searchParams.set('id', msgId);
    location.href = url;
  });

  // Message - inspect
  $('.forum').on('click', '.js-message-inspect', function () {
    const $button = $(this);
    const $container = $button.closest('div.message');
    const topicId = $('#topicId').val();
    const msgId = $container.attr('data-id');

    $.ajax({
      type: 'POST',
      url: `/forum/${topicId}/quickedit/${msgId}`,
      data: {},
      timeout: 10000,
      dataType: 'json',
      success : function (data) {
        if (data.error) {
          Modal.alert(data.error.message, data.error.code);
          return;
        }
        Modal.showtext(
          data.message,
          'Inspect BBCode',
          [
            {
              title: 'How to use BBCode?',
              class: 'js-reply-bbcode',
            }
          ]
        );
      },
      error: function () {
        Modal.alert('Something went wrong. Please try again later.');
      }
    });
  });

  // ------------------------------------------------------------
  // Timeline モードで利用する処理
  // ------------------------------------------------------------

  // Message - toggle ignore
  $('.forum.timeline').on('click', '.message.ignored .message-header', function () {
    $(this).closest('.message').toggleClass('hide-message');
  });

  // Message - reply
  $('.forum.timeline').on('click', '.js-timeline-reply', function () {
    const $button = $(this);
    const $container = $button.closest('div.message');
    const username = $container.attr('data-user');
    $container.startTimelineReply(username, null);
  });

  // Message - quote
  $('.forum.timeline').on('click', '.js-timeline-quote', function () {
    const $button = $(this);
    const $container = $button.closest('div.message');
    $container.startTimelineQuote();
  });

  // ------------------------------------------------------------
  // Thread モードで利用する処理
  // ------------------------------------------------------------

  $('body.page-forum').on('click', '.mods-title', function () {
    const myrel = $(this).attr('rel');
    if (myrel === undefined || myrel == '') {
      return;
    }
    location.href = `/staff#${myrel}`;
  });

  // Message - childs
  $('.forum.thread').on('click', '.js-thread-childs', function () {
    const $button = $(this);
    const $container = $button.closest('div.message');

    // リプライを閉じる
    if ($container.attrAuto('data-replies-open')) {
      const $replies = $container.getRelatedReplies();
      $replies.slideUp('normal', function () {
        $button.removeClass('pressed');
        $container.attr('data-replies-open', 0);
      });
      return;
    }
    // リプライを開く
    const $replies = $container.getRelatedReplies();
    if (!$container.attrAuto('data-replies-loaded')) {
      const $loadmore = $replies.children('.js-thread-loadmore[data-max-id=0]');
      $loadmore.trigger('click');
      $container.attr('data-replies-loaded', 1);
    }
    $replies.slideDown('normal', function () {
      $button.addClass('pressed');
      $container.attr('data-replies-open', 1);
    });
  });

  // Message - reply
  $('.forum.thread').on('click', '.js-thread-reply', function () {
    const $button = $(this);
    const $container = $button.closest('div.message');
    const username = $container.attr('data-user');
    $container.startThreadReply(username, null);
  });

  $('.forum.thread').on('click', '.js-replyguide-reply', function () {
    const $button = $(this);
    const $replies = $button.closest('div.replies');
    const parentId = $replies.attr('data-id');
    const $parent = $(`div.message[data-id=${parentId}]`);
    $parent.startThreadReply(null, null);
  });

  $('.forum.thread').on('click', '.js-replyguide-checknew', function () {
    const $button = $(this);
    const $replies = $button.closest('div.replies');
    const parentId = $replies.attr('data-id');
    const $loadmore = $replies.children('.js-thread-loadmore[data-max-id=0]');
    // loadmoreが存在する場合はクリックする
    if ($loadmore.length > 0) {
      $loadmore.trigger('click');
      return;
    }
    // loadmoreを追加しクリックする
    $replies.appendLoadmore(
      parentId,
      $replies.getLastMessageId(),
      0,
      1
    ).trigger('click');
  });

  $('.forum.thread').on('click', '.js-thread-loadmore', function () {
    const $loadmore = $(this);
    const $replies = $loadmore.closest('div.replies');
    if ($loadmore.hasClass('loading')) {
      return false;
    }
    // GA4クリックイベントを送信する
    const ga_parent = ($loadmore.attr('data-parent') == 0) ? 'parent' : 'replies';
    const ga_position = $loadmore.attr('data-position');
    GtmJsEvent.send(`forum-load-${ga_parent}-${ga_position}-thread`);
    // GA4ページビューイベントを送信する
    if ($loadmore.attr('data-parent') == 0) {
      GtmJsEvent.loadready(`${location.href}&dummy_action=${ga_position}`);
    }

    const topicId = $('#topicId').val();
    $loadmore.addClass('loading');
    $.ajax({
      type    : 'POST',
      url     : `/forum/thread/${topicId}/messages`,
      cache   : false,
      data    : {
        parent: $loadmore.attr('data-parent'),
        lastId: $loadmore.attr('data-last-id'),
        maxId: $loadmore.attr('data-max-id'),
      },
      dataType: 'json',
      timeout: 10000,
      success : function (data) {
        $loadmore.removeClass('loading');

        /* post error */
        if (!data.result) {
          // 新着投稿がない場合
          if (data.error.code == 12) {
            // 件数を0件にしてテキストを更新する
            $loadmore.attr('data-count', 0);
            $loadmore.updateLoadmoreText();
            return;
          }
          Modal.alert(data.error.message, data.error.code);
          return;
        }

        for (const message of data.replies) {
          const $message = $($('#template_reply').html());
          $message.buildThreadMessage(message, data.replied[message.reply_to]);
          // loadmoreを利用したメッセージ追加
          $replies.insertMessage($message, $loadmore);
          // repliedの表示有無をチェックする
          $message.checkReplied();
        }

        // loadmore表記を更新する
        $loadmore.attr('data-last-id', data.after.last);
        $loadmore.attr('data-count', data.after.count);
        $loadmore.updateLoadmoreText();
        // 下部バー表示判定
        checkShowReplyBar();
      },
      error : function (XMLHttpRequest, textStatus, errorThrown) {
        Modal.alert('There was an error loading, please try again.');
        $loadmore.removeClass('loading');
      },
    });
  });

  // 個別ポストが存在する場合、その位置まで初期スクロールする
  const $individual = $('div.message.individual');
  if ($individual.length > 0) {
    // ハッシュがある場合、いったんスクロールを停止させる
    if (location.hash) {
      $('html,body').stop().scrollTop(0);
    }
    // 待機後にスクロールする
    setTimeout(function(){
      $individual.highlight();
      $individual.scrollToMessage();
    }, 100);
  }
});

function getQuoteSelectionText()
{
  // Locked topic
  if ($('#topicLocked').val() == 1 && !$('#topicAdmin').val()) {
    return;
  }

  const selection = document.getSelection();
  const parentDiv = getClosestParentDiv(selection);
  if (!parentDiv) {
    return;
  }

  const msgId = parentDiv.attr('data-id');
  const username = parentDiv.attr('data-user');
  const selectionText = selection.toString();
  if (!selectionText) {
    return;
  }

  if (msgId && username) {
    return `[quote=${username} message=${msgId}]${selectionText}[/quote]`;
  }
  if (username) {
    return `[quote=${username}]${selectionText}[/quote]`;
  }
  if (msgId) {
    return `[quote message=${msgId}]${selectionText}[/quote]`;
  }
  return `[quote]${selectionText}[/quote]`;
}

function getClosestParentDiv(selection)
{
  if (selection.isCollapsed) return;
  const anchorNode = $(selection.anchorNode);
  const focusNode = $(selection.focusNode);

  // [code]の中
  if (anchorNode.closest('div.codetext').length > 0 ||
    focusNode.closest('div.codetext').length > 0) {
    return;
  }

  // [quote]の中
  if (anchorNode.closest('div.quotetext').length > 0 ||
    focusNode.closest('div.quotetext').length > 0) {
    const anchorClosest = anchorNode.closest('div.quotetext');
    const focusClosest = focusNode.closest('div.quotetext');
    if (anchorClosest.length == 0 || focusClosest.length == 0) return;
    if (anchorClosest.attr('data-id') != focusClosest.attr('data-id')) return;
    return focusClosest;
  }

  // メッセージの中
  return getClosestMessageDiv(selection);
}

function getClosestMessageDiv(selection)
{
  if (selection.isCollapsed) return;
  const anchorNode = $(selection.anchorNode);
  const focusNode = $(selection.focusNode);

  if (anchorNode.closest('table.body').length > 0 &&
    focusNode.closest('table.body').length > 0) {
    const anchorClosest = anchorNode.closest('table.body');
    const focusClosest = focusNode.closest('table.body');
    if (anchorClosest.length == 0 || focusClosest.length == 0) return;
    if (anchorClosest.attr('data-id') != focusClosest.attr('data-id')) return;
    const wrapperClosest = focusClosest.closest('div.message');
    if (wrapperClosest.length == 0) return;
    return wrapperClosest;
  }
  return null;
}

function checkShowReplyBar()
{
  // メッセージが規定件数以下の場合（親子ともに含む）
  if ($('div.message').length <= TOPIC_NAVBAR_AUTOHIDE_THRESHOLD) return;
  // メッセージが規定件数を超えた場合は常に表示する
  $('.topic-reply-container.hide').removeClass('hide');
}

// すべて開く
window.MAL.expandAllConversation = function() {
  $('div.message[data-parent=0]').each(function(){
    const $container = $(this);
    if ($container.attrAuto('data-replies-open')) return;
    $container.find('.js-thread-childs').trigger('click');
  });
};

// すべて閉じる
window.MAL.collapseAllConversation = function() {
  $('div.message[data-parent=0]').each(function(){
    const $container = $(this);
    if (!$container.attrAuto('data-replies-open')) return;
    $container.find('.js-thread-childs').trigger('click');
  });
};
