const $ = window.$;
const _ = window._;

import Editor from "../../../common/modules/editor";
import Modal from "../../../common/modules/modal";

require("../../../common/pages/forum/forum")

const touchEventName = (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) ? 'touchend' : 'click';

$(function () {
  // ------------------------------------------------------------
  // Timeline モードで利用する処理
  // ------------------------------------------------------------

  // Message - report
  $('.forum.timeline').on('click', '.js-timeline-report', function () {
    const topicId = $('#topicId').val();
    const msgid = $(this).data('id');
    location.href = `/modules.php?go=report&type=forummessagenew&id=${msgid}&id2=${topicId}`;
  });

  // Message - edit
  $('.forum.timeline').on('click', '.js-timeline-edit', function () {
    const msgId = $(this).data('id');
    location.href = `/forum/?action=message&msgid=${msgId}`;
  });

  // Message - edit by news mod
  $('.forum.timeline').on('click', '.js-timeline-newsedit', function () {
    const msgId = $(this).data('id');
    location.href = `/panel.php?go=news&id=${msgId}`;
  });

  // Message - delete
  $('.forum.timeline').on('click', '.js-timeline-delete', function () {
    const topicId = $('#topicId').val();
    const msgId = $(this).data('id');
    const deleteYes = $('<button class="yes mal-btn danger small outline noborder">yes</button>');
    const deleteNo = $('<button class="no mal-btn secondary small outline noborder">no</button>');
    const deleteContainer = $('<span class="confirmDelete"></span>');
    deleteContainer.append('<span class="text"><i class="fa-solid fa-trash-can fa-fw mr4"></i>Are you sure?</span>');
    deleteContainer.append(deleteYes);
    deleteContainer.append('<span class="ml4 mr4">/</span>');
    deleteContainer.append(deleteNo);

    deleteYes.on('click', function(){
      deleteYes.prop('disabled', true);
      $.ajax({
        type: "POST",
        url: `/forum/${topicId}/quickedit/${msgId}/delete`,
        cache: false,
        data: {},
        timeout: 10000,
        dataType: "json",
        success : function (data) {
          if (data.error) {
            Modal.alert(`Error: ${data.error.message}`);
            deleteYes.prop('disabled', false);
            return;
          }
          $(`#msg${msgId}`).slideUp();
        }
      });
    });
    deleteNo.on('click', function(){
      $(`.js-timeline-delete[data-id=${msgId}]`).show();
      deleteContainer.remove();
    });

    $(this).after(deleteContainer);
    $(this).hide();
  });

  // BoardのIgnoreボタン
  $(".js-ignore-board-button").on('click', function () {
    const boardId = $(this).data('id');
    ignoreBoard(boardId, $(this));
  });

  // Topic一覧でのIgnoreボタン
  $(".js-ignore-topic-button").on('click', function () {
    const topicId = $(this).data('id');
    const rowName = $(this).data("row");
    const mode = $(this).data("mode");
    ignoreTopic(topicId, rowName, mode);
  });

  // Topic一覧でのWatchボタン
  $(".js-watch-topic-button").on('click', function () {
    const topicId = $(this).data('id');
    watchTopic(topicId);
  });

  // Christmas Layout $(".page-common.christmas .forum-topic-message")
  if ($(".page-common.christmas").exists()) {
    $(".forum-topic-message .gift, .message .js-message-gift").on({
      mouseenter: function(){
        let icon_path = "/images/event/2022_christmas/icn_gift_forum.gif?v=" + Date.now();
        $(this).find('.fa-gift').css("visibility", "hidden");
        $(this).find('.fa-gift').before('<i class="christmas-icn-gift-forum"></i>');
        $(this).find('.christmas-icn-gift-forum').css({
          "position": "absolute",
          "background-image": `url("${icon_path}")`,
          "background-position": "left top",
          "background-repeat": "no-repeat",
          "background-size": "31.8px auto",
          "display": "inline",
          "width": "31.8px",
          "height": "19px",
          "content": "''",
          "margin-top": "-4px",
          "margin-left": "-9.1px",
        });
      },
      mouseleave: function(){
        $(this).find('.fa-gift').css("visibility", "visible");
        $(this).find('.christmas-icn-gift-forum').remove();
      },
    })
  }
});

function ignoreBoard(boardid, $el) {
  $el.prepend("<i class='fa-solid fa-spinner fa-spin mr4'></i>");
  $.ajax({
    type: "POST",
    url: `/forum/board/${boardid}/toggle-ignore`,
    cache: false,
    data: {},
    dataType: "json",
    timeout: 10000,
    success: function (data) {
      if (data.error) {
        Modal.alert(data.error.message);
        return;
      }
      /* Ignored */
      if (data.ignored) {
        $el.addClass('pressed');
        $el.html("Un-ignore Board");
        return;
      }
      /* Not Ignored */
      $el.removeClass('pressed');
      $el.html("Ignore Board");
    },
  });
}

function ignoreTopic(topic_id, idName, cVal) {
  $(`.ignore-state-icon[data-topic-id=${topic_id}]`).addClass('fa-spinner fa-spin').removeClass('fa-circle-minus');
  $.ajax({
    type: "POST",
    url: `/forum/${topic_id}/toggle-ignore`,
    cache: false,
    data: {},
    dataType: "json",
    timeout: 10000,
    success: function (data) {
      $(`.ignore-state-icon[data-topic-id=${topic_id}]`).removeClass('fa-spinner fa-spin').addClass('fa-circle-minus');
      if (data.error) {
        Modal.alert(data.error.message);
        return;
      }
      /* Ignored */
      if (data.ignored) {
        $(`tr[data-topic-id=${topic_id}]`).hide();
        return;
      }
      /* Not Ignored */
      $(`tr[data-topic-id=${topic_id}]`).show();
    },
    error: function (XMLHttpRequest, textStatus, errorThrown) {
      $(`.ignore-state-icon[data-topic-id=${topic_id}]`).removeClass('fa-spinner fa-spin').addClass('fa-circle-minus');
    },
  });
}

function watchTopic(topic_id) {
  $(`.watch-state-icon[data-topic-id=${topic_id}]`).addClass('fa-spinner fa-spin').removeClass('fa-circle');

  $.ajax({
    type: "POST",
    url: `/forum/${topic_id}/toggle-watch`,
    cache: false,
    data: {},
    dataType: "json",
    timeout: 10000,
    success: function (data) {
      $('.js-toggle-watch-topic').prop('disabled', false);
      $('.js-toggle-watch-topic i.loading').remove();
      if (data.error) {
        Modal.alert(data.error.message);
        return;
      }
      /* Watched */
      if (data.watched) {
        $(`.watch-state-icon[data-topic-id=${topic_id}]`)
          .removeClass('fa-spinner fa-spin')
          .addClass('fa-circle watched')
          .attr('title', 'You are watching this topic');
        return;
      }
      /* Unwatched */
      $(`.watch-state-icon[data-topic-id=${topic_id}]`)
        .removeClass('fa-spinner fa-spin watched')
        .addClass('fa-circle')
        .attr('title', 'You are not watching this topic');
    },
    error: function (XMLHttpRequest, textStatus, errorThrown) {
      $('.js-toggle-watch-topic').prop('disabled', false);
      $('.js-toggle-watch-topic i.loading').remove();
    },
  });
}

require("../../../common/google_form_link")
