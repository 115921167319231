var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root for-pc" },
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _vm.isShown
          ? _c(
              "div",
              {
                staticClass: "modal-content",
                attrs: { id: "gdpr-modal-bottom" }
              },
              [
                _c("div", { staticClass: "modal-wrapper" }, [
                  _c("div", { staticClass: "modal-container" }, [
                    _c("div", { staticClass: "text" }, [
                      _c("p", [
                        _vm._v(
                          "\n              To improve your experience on our site, we use cookies to provide you with a secure log-in and to remember log-in details, collect statistics to optimize site functionality, and deliver content tailored to your interests. See our "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "/about/cookie_policy",
                              target: "_blank"
                            }
                          },
                          [_vm._v("Cookie Policy")]
                        ),
                        _vm._v(" to learn more.\n            ")
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("\n              We have updated our "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "/about/privacy_policy",
                              target: "_blank"
                            }
                          },
                          [_vm._v("Privacy Policy")]
                        ),
                        _vm._v(", "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "/about/terms_of_use",
                              target: "_blank"
                            }
                          },
                          [_vm._v("Terms of Use Agreement")]
                        ),
                        _vm._v(", and "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "/about/notice_at_collection",
                              target: "_blank"
                            }
                          },
                          [_vm._v("Notice at Collection")]
                        ),
                        _vm._v(
                          ", effective as of March 27, 2025.\n            "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "button-wrapper" }, [
                      _c("button", { on: { click: _vm.close } }, [
                        _vm._v("OK")
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "/about/privacy_policy",
                              target: "_blank"
                            }
                          },
                          [_vm._v("More Info")]
                        )
                      ])
                    ])
                  ])
                ])
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }